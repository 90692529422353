import React, { useState } from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { computeWidthStyle } from '../../../util/compute-width';
import styles from '../style.css';

export const Post = ({
    item,
    displayed,
}) => {
    const t = usePhraseTranslater();
    const MAX_CAPTION_LENGTH = 100;
    const isVideo = item.mediaType === 'VIDEO';
    const [isPlaying, setIsPlaying] = useState(false);

    const truncateText = (text, maxLength) => (text.length > maxLength ? `${text.slice(0, maxLength)}...` : text);
    return (
        <div className={styles.post} style={computeWidthStyle(displayed)}>
            {isVideo ? (
                <div
                    className={styles.videoWrapper}
                    onClick={() => setIsPlaying(true)}
                    tabIndex={0}
                    role="button"
                    onKeyDown={(event) => {
                        if (event.key === 'Enter' || event.key === ' ') {
                            setIsPlaying(true);
                        }
                    }}
                >
                    {isPlaying ? (
                        // eslint-disable-next-line jsx-a11y/media-has-caption
                        <video controls autoPlay className={styles.video}>
                            <source src={item.imageUrl} type="video/mp4" />
                        </video>
                    ) : (
                        <>
                            <img src={item.thumbnailUrl} alt="Instagram Video Thumbnail" className={styles.image} />
                            <span className={styles.playIcon}>▶</span>
                        </>
                    )}
                </div>
            ) : (
                <img src={item.imageUrl} alt="Instagram Post" className={styles.image} />
            )}
            <div className={styles.overlay}>
                <p className={styles.caption}>{truncateText(item.caption, MAX_CAPTION_LENGTH)}</p>
                <a href={item.permalink} target="_blank" rel="noopener noreferrer" className={styles.link}>
                    {t('Learn More')}
                </a>
            </div>
        </div>
    );
};
