import React from 'react';
import { ItemSlider } from '../../item-slider';
import { Post } from '../post/post';
import styles from '../style.css';

export const InstaSlider = ({
    show,
    items,
    refetch,
    page,
}) => (
    <div className={styles.slider}>
        <ItemSlider
            show={show}
            items={items}
            itemsClassName={styles.itemsClass}
            arrowClassName={styles.arrowClassInsta}
            Item={Post}
            refetch={refetch}
            page={page}
            className="instagram"
        />
    </div>
);
