import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useResourceType } from '@silkpwa/module/router/use-location';
import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { connectProduct } from '@silkpwa/module/react-component/product-config/connect-product';
import { GET_INSTAGRAM_FEED } from '../../../graphql/meta/instagram';
import screenSwitch from '../../styles/screen-switch.css';
import { InstaSlider } from './slider/insta-slider';
import { InstagramSVG } from './icon/icon';
import styles from './style.css';

interface InstagramFeedProps {
    tags: string;
}

export const useFeedData =
    (currentPage: number, pageType: string, tags: string, igEnabled: boolean) => useQuery(GET_INSTAGRAM_FEED, {
        skip: !pageType || !igEnabled,
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-and-network',
        variables: {
            input: {
                currentPage,
                pageType,
                tags,
            },
        },
    });
export const InstagramFeed: React.FC<InstagramFeedProps> = ({ tags }) => {
    const t = usePhraseTranslater();
    const locationSource = useResourceType();
    const [currentPage, setCurrentPage] = useState(1);
    const [hasLoadedOnce, setHasLoadedOnce] = useState(false);
    const [pages, setPages] = useState<any[][]>([]);
    const [storedFormattedTags, setStoredFormattedTags] = useState<string>('');
    const pageType = locationSource.resourceType ?? '';
    const metaConfig = useConfig();
    const igEnabled = metaConfig.extension_attributes?.ig_enabled ?? false;

    const {
        loading,
        error,
        data,
        refetch,
    } = useFeedData(currentPage, pageType, tags, igEnabled);
    const newItems = data?.getInstagramFeed?.items || [];
    useEffect(() => {
        refetch();
    }, [currentPage, refetch]);
    useEffect(() => {
        if (!loading && newItems.length > 0) {
            setHasLoadedOnce(true);
        }
    }, [loading, newItems]);

    const hasMoreItems = newItems.length > 0;

    useEffect(() => {
        if (hasMoreItems) {
            setPages((prevPages) => {
                const updatedPages = [...prevPages];
                updatedPages[currentPage - 1] = newItems;
                return updatedPages;
            });
        }
    }, [newItems, currentPage]);

    const items = pages.flat() || [];
    const headerTags = data?.getInstagramFeed?.headerTags || '';
    useEffect(() => {
        if (!storedFormattedTags && headerTags) {
            const resolvedTags = tags === 'homepage' ? headerTags : tags;
            const formatted = resolvedTags
                .split(',')
                .map(tag => (tag.trim()
                    .startsWith('#') ? tag.trim() : `#${tag.trim()}`))
                .join(', ');

            setStoredFormattedTags(formatted);
        }
    }, [headerTags, tags]);
    if (error) {
        return null;
    }

    const handlePageChange = () => {
        if (hasMoreItems) {
            setCurrentPage(prev => prev + 1);
        }
    };

    if ((!loading && items.length === 0) || !tags) {
        return null;
    }
    const igUsername = data?.getInstagramFeed?.igUsername || 'chef_works';
    if (!hasLoadedOnce) {
        return null;
    }
    return (
        <div className={styles.instaPosts}>
            <h2 className={styles.instaHeader}>
                <span>{t(storedFormattedTags)}</span>
                <span className={styles.igIcon}><InstagramSVG /></span>
            </h2>

            {items.length > 0 && (
                <>
                    <div className={screenSwitch.showOnDesktop}>
                        <InstaSlider show={5} items={items} refetch={handlePageChange} page={currentPage} />
                    </div>
                    <div className={`${screenSwitch.showOnLargeMobile} ${screenSwitch.showOnMediumMobile}`}>
                        <InstaSlider show={3} items={items} refetch={handlePageChange} page={currentPage} />
                    </div>
                    <div className={`${screenSwitch.showOnSmallMobile} ${screenSwitch.showOnVerySmallMobile}`}>
                        <InstaSlider show={1} items={items} refetch={handlePageChange} page={currentPage} />
                    </div>
                </>
            )}

            <div className={styles.followContainer}>
                <a
                    href={`https://www.instagram.com/${igUsername}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.followButton}
                >
                    {t('Follow')}
                </a>
            </div>
        </div>
    );
};

const ConnectedInstagramGrid = connectProduct(InstagramFeed);
export { ConnectedInstagramGrid as InstagramGrid };
