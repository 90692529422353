import { gql, TypedDocumentNode } from '@apollo/client';

export interface IInstagramFeedItem {
    entityId: string;
    imageUrl: string;
    caption: string;
    permalink: string;
    timestamp: string;
    tags: string;
    thumbnailUrl: string;
    mediaType: string;
}

export interface IPageInfo {
    currentPage: number;
    pageSize: number;
    totalPages: number;
}

export interface IInstagramFeedOutput {
    getInstagramFeed: {
        items: IInstagramFeedItem[];
        totalCount: number;
        pageInfo: IPageInfo;
        headerTags: string;
        igUsername: string;
    };
}

export interface IInstagramFeedInput {
    currentPage: number;
    tags?: string;
    pageType: string;
}

export const GET_INSTAGRAM_FEED: TypedDocumentNode<IInstagramFeedOutput, {input: IInstagramFeedInput}> = gql`
    query GET_INSTAGRAM_FEED($input: InstagramFeedInput!) {
        getInstagramFeed(input: $input) {
            items {
                entityId
                imageUrl
                caption
                permalink
                timestamp
                thumbnailUrl
                mediaType
            }
            totalCount
            pageInfo {
                currentPage
                pageSize
                totalPages
            }
            headerTags
            igUsername
        }
    }
`;
